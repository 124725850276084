import { mapActions } from "vuex";
import orderItem from "../../../../components/order-item/index.vue";

export default {
  name: "client-history",
  components: {
    orderItem,
  },
  data() {
    return {
      loadingOrders: false,
      clientId: 0,
      clientOrders: [],
      paginator: {
        hasNextPage: false,
        page: 1,
        perPage: 15,
      },
    };
  },
  created() {
    this.clientId = this.$route.params.id;
    this.fetchClientUserData(this.clientId);
    this.loadOrders();
  },
  methods: {
    ...mapActions({
      fetchClientUserData: "profile/GET_CLIENT_USER_DATA",
      fetchClientOrders: "profile/GET_CLIENT_ORDER",
    }),
    async paginateOrders() {
      this.paginator.page += 1;

      await this.loadOrders();
    },
    async loadOrders() {
      this.loadingOrders = true;

      if (this.paginator.page === 1) {
        this.clientOrders = [];
      }

      const clientOrders = await this.fetchClientOrders({
        clientId: this.clientId,
        page: this.paginator.page,
        perPage: this.paginator.perPage,
      });

      this.clientOrders.push(...clientOrders.data);

      this.paginator.hasNextPage = !!clientOrders.links.next;

      this.loadingOrders = false;
    },
  },
};
